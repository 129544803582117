import i18n from "@/plugins/i18n";

export function getInvoicesH() {
  return [
    { text: i18n.t("subscriber"), value: "subscriber", sortable: false },
    { text: i18n.t("country"), value: "country", sortable: false },
    { text: i18n.t("email"), value: "email", sortable: false },
    { text: i18n.t("mobile"), value: "mobile", sortable: false },
    { text: i18n.t("date"), value: "date", sortable: false },
    {
      text: i18n.t("contract_start"),
      value: "contract_start",
      sortable: false,
    },
    {
      text: i18n.t("contract_expiry"),
      value: "contract_expiry",
      sortable: false,
    },
    { text: i18n.t("next_payment"), value: "next_payment", sortable: false },
    { text: i18n.t("point_sales"), value: "point_sales", sortable: false },
    { text: i18n.t("package_name"), value: "package_name", sortable: false },
    { text: i18n.t("payment_plan"), value: "package_plan", sortable: false },
    {
      text: i18n.t("package_amount"),
      value: "package_amount",
      sortable: false,
    },
    { text: i18n.t("total_amount"), value: "total_amount", sortable: false },
    { text: i18n.t("invoice_no"), value: "invoice_no", sortable: false },
    { text: i18n.t("smart_card"), value: "smart_card", sortable: false },
    { text: i18n.t("item_type"), value: "item_type", sortable: false },
    { text: i18n.t("rrn"), value: "rrn", sortable: false },
  ];
}
export function getEmailsH() {
  return [
    { text: i18n.t("subject"), value: "subject" },
    { text: i18n.t("pending"), value: "pending_emails_count" },
    { text: i18n.t("sent"), value: "sended_emails_count" },
    { text: i18n.t("send_failed"), value: "unsended_emails_count" },
    { text: i18n.t("created"), value: "created_at" },
    { text: i18n.t("modified"), value: "updated_at" },
    { text: i18n.t("actions"), value: "actions", sortable: false },
  ];
}

export function getMessagesH() {
  return [
    { text: i18n.t("message"), value: "text" },
    { text: i18n.t("pending"), value: "pending_messages_count" },
    { text: i18n.t("sent"), value: "sended_messages_count" },
    { text: i18n.t("send_failed"), value: "unsended_messages_count" },
    { text: i18n.t("created"), value: "created_at" },
    { text: i18n.t("modified"), value: "updated_at" },
    { text: i18n.t("actions"), value: "actions", sortable: false },
  ];
}

export function getSelectedEH() {
  return [
    { text: i18n.t("subject"), value: "subject" },
    { text: i18n.t("created"), value: "created_at" },
    { text: i18n.t("modified"), value: "updated_at" },
    { text: i18n.t("show"), value: "show", sortable: false },
  ];
}
export function getSelectedMH() {
  return [
    { text: i18n.t("message"), value: "text" },
    { text: i18n.t("created"), value: "created_at" },
    { text: i18n.t("modified"), value: "updated_at" },
    { text: i18n.t("show"), value: "show", sortable: false },
  ];
}
